import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  loadAll,
  loadinfo,
  savenotes,
  updateStore,
  loadLogs,
  statusUpdate,
  resendwelcomeemail,
} from 'api/storeinfo'
import actions from './actions'

export function* LOAD_ALL_ACTIVE() {
  const { response } = yield call(loadAll)
  if (response) {
    const { businesses } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        storelist: businesses,
      },
    })
  }
}

export function* RESENDEMAIL({ payload }) {
  const { response } = yield call(resendwelcomeemail, payload)
  if (response) {
    console.log('res', response)
  }
}

export function* LOAD_INFO(payload) {
  const userId = payload.payload
  const { response } = yield call(loadinfo, userId)
  if (response) {
    console.log('business Load', response)
    const { businesses } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data1: businesses.name,
        data2: businesses.type,
        data3: businesses.locations.address,
        data4: businesses.locations.city,
        data5: businesses.locations.province,
        data6: businesses.locations.postal_code,
        data7: businesses.locations.country,
        data8: businesses.time_zone,
        data9: businesses.user.first_name,
        data10: businesses.user.last_name,
        data11: businesses.user.email,
        data12: businesses.user.phone_number,
        // data13: businesses.account_type,
        data14: businesses.locations.contact.alt_phone_number,
        data15: businesses.logo_url,
        data16: businesses.account_type,
        data17: businesses?.reseller?.company,
        data18: businesses?.reseller?.name,
        data19: businesses?.reseller?.role,
        reseller: businesses?.reseller,
        data20: businesses?.partner?.restaurant_software,
        data21: businesses?.partner_id,
        data22: businesses?.is_charge_discount,
        data23: businesses?.charge_discount_value,
      },
    })
  }
}

export function* LOAD_DEVICES(payload) {
  const userId = payload.payload
  const { response } = yield call(loadinfo, userId)
  if (response) {
    const { businesses } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        divicelist: businesses,
      },
    })
  }
}

export function* LOAD_NOTES(payload) {
  const userId = payload.payload
  const { response } = yield call(loadinfo, userId)
  if (response) {
    const { businesses } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        notesinfo: businesses.notes,
      },
    })
  }
}

export function* SAVE_NOTES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(savenotes, payload)
  yield put({
    type: actions.ADDNOTES,
    payload: {
      notesinfo: response.data.data.notes,
    },
  })
}

export function* STOP_SERVICE({ payload }) {
  const path = window.location.pathname
  const userId = path.replace('/manage/store-info/', '')
  const { response } = yield call(statusUpdate, payload)
  if (response) {
    yield call(LOAD_DEVICES, { payload: userId })
  }
}

export function* UPDATE_STORE({ payload }) {
  const path = window.location.pathname
  const userId = path.replace('/manage/store-info/', '')
  const { response } = yield call(updateStore, userId, payload)
  yield put({
    type: actions.UPDATE_STORE_INFO,
    payload: {
      data1: response.data.data.business.name,
      data2: response.data.data.business.type,
      data3: response.data.data.location.address,
      data4: response.data.data.location.city,
      data5: response.data.data.location.province,
      data6: response.data.data.location.postal_code,
      data7: response.data.data.location.country,
      data8: response.data.data.business.time_zone,
      data9: response.data.data.user.first_name,
      data10: response.data.data.user.last_name,
      data11: response.data.data.user.email,
      data12: response.data.data.location.contact.phone_number,
      data14: response.data.data.location.contact.alt_phone_number,
      data16: response.data.data.business.account_type,
      data21: response.data.data.business.partner_id,
      data22: response.data.data.business?.is_charge_discount,
      data23: response.data.data.business?.charge_discount_value,
      updating: false,
    },
  })
}

export function* LOAD_LOGS(payload) {
  const path = window.location.pathname
  const businessId = path.replace('/manage/store-info/', '')
  const deviceId = payload.payload
  const { response } = yield call(loadLogs, businessId, deviceId)
  if (response) {
    const { logs } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        logsdata: logs,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ALL_ACTIVE, LOAD_ALL_ACTIVE),
    takeLatest(actions.LOAD_INFO, LOAD_INFO),
    takeLatest(actions.LOAD_DEVICES, LOAD_DEVICES),
    takeEvery(actions.LOAD_NOTES, LOAD_NOTES),
    takeEvery(actions.SAVE_NOTES, SAVE_NOTES),
    takeEvery(actions.UPDATE_STORE, UPDATE_STORE),
    takeLatest(actions.LOAD_LOGS, LOAD_LOGS),
    takeLatest(actions.STOP_SERVICE, STOP_SERVICE),
    takeLatest(actions.RESENDEMAIL, RESENDEMAIL),
  ])
}
